<template>
  <!-- navigation START -->
  <div id="nav">
    <div class="main-navigation">
      <!-- logo START -->
      <div class="logo logo-centered">
        <router-link :to="'/'">
          <img width="320" height="" :src="iconLogo.src" />
        </router-link>
      </div>
      <!-- logo END -->
    </div>
  </div>
  <!-- navigation END -->
</template>

<script>
import ICONS from '../../utils/icons';

const {
  iLogo,
} = ICONS;

export default {
  name: 'app-navigation',
  data() {
    return {
      iconLogo: {
        src: iLogo.defaultUrl,
      },
    };
  },
};
</script>
